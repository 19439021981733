<template>
  <div>
    <q-input :dense="true" class="col-2" outlined v-model="value">
      <template v-slot:append>
        <q-btn flat round color="primary" icon="PROSmart-Edit" @click="open" />
      </template>
    </q-input>

    <pro-editor type="json" ref="proEditor" @confirm="confirm" />
  </div>
</template>

<script>
import ProEditor from "@/components/PROSmart/ProEditor.vue";

export default {
  name: "ProJsonSetting",
  components: { ProEditor },
  props: {
    value: {
      type: String,
      required: true,
    },
  },
  methods: {
    open() {
      this.$refs.proEditor.open(this.value);
    },
    /** @param {string} str */
    confirm(str) {
      this.$emit("input", str);
    },
  },
};
</script>
