<template>
  <code-editor ref="aceEditor" @confirm="confirm"></code-editor>
</template>

<script>
export default {
  name: "ProEditor",
  props: {
    type: {
      type: String,
      default: "javascript",
    },
  },
  methods: {
    /**
     * @param {string} string
     * @param {'json'| 'javascript'} [type]
     */
    open(string, type) {
      if (type) {
        this.type = type;
      }

      let ace = this.$refs.aceEditor;
      ace.type = this.type;

      try {
        string = JSON.stringify(JSON.parse(string), null, 2);

        // eslint-disable-next-line no-empty
      } catch (e) {}

      ace.open(string);
    },
    /** @param {string} str **/
    confirm(str) {
      this.$emit("confirm", str);
    },
  },
};
</script>
